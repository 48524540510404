const PinInput = {
  variants: {
    onboarding: {
      w: '82.32px',
      h: '98.79px',
      color: '#213A62',
      fontSize: '48px',
      fontWeight: '700',
      fontFamily: 'Montserrat',
      borderRadius: '10.9763px',
      border: '1.4px solid #043C68',
      _invalid: {
        borderColor: '#FF3A3A',
      },
    },
    paymentBillBase: {
      w: '50px',
      h: '60px',
      color: '#213A62',
      fontSize: '24px',
      fontWeight: '700',
      fontFamily: 'Montserrat',
      borderRadius: '10.9763px',
      border: '1.4px solid #043C68',
      _invalid: {
        borderColor: '#FF3A3A',
      },
    },
    paymentBill: {
      w: '82.32px',
      h: '98.79px',
      color: '#213A62',
      fontSize: '48px',
      fontWeight: '700',
      fontFamily: 'Montserrat',
      borderRadius: '10.9763px',
      border: '1.4px solid #043C68',
      _invalid: {
        borderColor: '#FF3A3A',
      },
    },
    pinAuthentication: {
      w: '40px',
      h: '48px',
      color: '#000000',
      fontSize: '16px',
      fontWeight: '600',
      fontFamily: 'Montserrat',
      borderRadius: '9px',
      border: '0.1rem solid #043C68',
      _invalid: {
        borderColor: '#FF3A3A',
      },
    },
  },
};

export default PinInput;
