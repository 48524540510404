const Button = {
  variants: {
    onboarding: {
      h: '48px',
      px: '24px',
      py: '12px',
      bg: '#183B65',
      color: 'white',
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '24px',
      borderRadius: '24px',
      fontFamily: 'Montserrat',
      _disabled: {
        opacity: 1,
        bg: '#D3D3D3',
        color: 'rgba(0, 0, 0, 0.32)',
      },
      _hover: {
        bg: '#086FB0',
        textDecoration: 'none',
        _disabled: {
          opacity: 1,
          bg: '#D3D3D3',
        },
      },
    },
    posCancel: {
      h: '48px',
      borderRadius: '24px',
      padding: '12px 24px',
      fontWeight: '600',
      lineHeight: '150%',
      fontSize: '16px',
    },
    pinConfirm: {
      h: '40px',
      px: '24px',
      py: '12px',
      bg: '#183B65',
      color: 'white',
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '24px',
      borderRadius: '24px',
      fontFamily: 'Montserrat',
      _disabled: {
        opacity: 1,
        bg: '#D3D3D3',
        color: 'rgba(0, 0, 0, 0.32)',
      },
      _hover: {
        bg: '#086FB0',
        textDecoration: 'none',
        _disabled: {
          opacity: 1,
          bg: '#e8e8e8',
        },
      },
    },
  },
  posRequest: {
    h: '48px',
    borderRadius: '24px',
    padding: '12px 24px',
    fontWeight: '600',
    lineHeight: '150%',
    fontSize: '16px',
  },
};

export default Button;
