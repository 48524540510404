import { getColor } from '@chakra-ui/theme-tools';

/** Input Syle */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fieldWithoutBorderVariant = (props: any) => {
  const { theme } = props;
  return {
    field: {
      background: 'white',
      border: 'none',
      borderRadius: 0,
      borderBottom: 2,
      borderBottomStyle: 'solid',
      color: getColor(theme, 'brand.500'),
      borderBottomColor: getColor(theme, 'brand.500'),
    },
  };
};

/** Input Syle */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fieldOutlineVariant = (props: any) => {
  const { theme } = props;
  return {
    field: {
      borderRadius: 'full',
      _focus: {
        borderColor: getColor(theme, 'brand.500'),
      },
    },
  };
};

const onboardingVariant = {
  field: {
    fontSize: '14px',
    color: '#494949',
    fontWeight: '500',
    fontFamily: 'Inter',
    borderRadius: '6px',
    lineHeight: '16.94px',
    py: { base: '14px', sm: 6 },
    px: { base: '30px', sm: 6 },
    border: '1px solid #8692A6',
    h: { base: '48px', sm: '64px' },
    _invalid: {
      color: '#FF3A3A',
      border: '1px solid #FF3A3A',
    },
    _focus: {
      _invalid: {
        color: '#FF3A3A',
        border: '1px solid #FF3A3A',
      },
      border: '1px solid #086FB0',
      boxShadow: '0px 4px 10px 3px rgba(0, 0, 0, 0.11)',
    },
  },
};

const Input = {
  variants: {
    outline: fieldOutlineVariant,
    onboarding: onboardingVariant,
    'without-border': fieldWithoutBorderVariant,
  },
};

export default Input;
