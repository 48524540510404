import React from 'react';
import { useToken } from '@chakra-ui/react';
import { Global, css } from '@emotion/react';

const GlobalStyles = () => {
  const [justa500, gray200, blue50, gray700, brand500] = useToken('colors', [
    'justa.500',
    'gray.200',
    'blue.50',
    'gray.700',
    'brand.500',
  ]);
  return (
    <Global
      styles={css`
        html,
        body,
        #__next {
          height: 100vh;
        }
        .Selectable
          .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
          background-color: #f0f8ff !important;
          color: #4a90e2;
        }
        .Selectable .DayPicker-Day {
          border-radius: 0 !important;
        }
        .Selectable .DayPicker-Day--start {
          border-top-left-radius: 50% !important;
          border-bottom-left-radius: 50% !important;
        }
        .Selectable .DayPicker-Day--end {
          border-top-right-radius: 50% !important;
          border-bottom-right-radius: 50% !important;
        }
        .DayPicker.Receivable {
          width: 100% !important;
        }
        .Receivable .DayPicker-Month {
          width: 100% !important;
        }
        .Receivable .DayPicker-Day {
          color: ${justa500};
          border: 1px solid ${gray200};
        }
        .Receivable .DayPicker-Day--today {
          color: ${justa500};
        }
        .Receivable
          .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
          background-color: ${blue50};
          color: ${justa500};
          border-radius: 0;
          border: 1px solid ${gray200};
        }
        .Receivable .DayPicker-Day--outside {
          background-color: ${gray200};
          border-radius: 0;
        }

        #menu div {
          width: 100%;
        }

        .DayPicker:not(.DayPicker--interactionDisabled)
          .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
          border-radius: 0;
        }
        .Receivable .DayPicker-Day--disabled {
          cursor: not-allowed;
          display: none;
        }
        .Receivable .DayPicker-Weekday {
          color: ${gray700};
        }

        .onboarding-logo-white {
          background-color: red;
        }

        .onboarding-circle-shape {
          position: absolute;
          bottom: 0;
        .jst-account-balance .rdp-day_selected:not([disabled]) {
          background-color: ${brand500};
          color: white;
        }
        .jst-account-balance .rdp-day_range_middle:not([disabled]) {
          opacity: 0.4;
        }

        .jst-account-balance .rdp-day_range_start {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        .jst-account-balance .rdp-day_range_end {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      `}
    />
  );
};

export default GlobalStyles;
