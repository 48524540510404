import {
  extendTheme,
  ThemeComponents,
  ComponentStyleConfig,
  withDefaultColorScheme,
} from '@chakra-ui/react';
import Text from './components/Text';
import Input from './components/Input';
import Button from './components/Button';
import PinInput from './components/PinInput';
import Container from './components/Container';
import FormLabel from './components/FormLabel';
import { Modal } from './components/Modal';

const COLORS = {
  brand: {
    // Cor primaria da Justa
    DEFAULT: '#076e95',
    50: '#88daf9',
    100: '#70d3f8',
    200: '#3fc4f6',
    300: '#0eb5f4',
    400: '#0992c6',
    500: '#076e95',
    600: '#054a64',
    700: '#022634',
    800: '#000203',
    900: '#000000',
  },
  payments: {
    // Cor primaria de pagamentos (Ex.: POS)
    DEFAULT: '#086FB0',
    50: '#A3D9FB',
    100: '#8ACFFA',
    200: '#5ABAF7',
    300: '#29A6F5',
    400: '#0A8EE1',
    500: '#086FB0',
    600: '#06507F',
    700: '#04314E',
    800: '#01131E',
    900: '#000000',
  },
  account: {
    // Cor primaria da Conta Justa
    DEFAULT: '#183B65',
    50: '#81ADE1',
    100: '#6D9FDC',
    200: '#4485D2',
    300: '#2C6BB7',
    400: '#22538E',
    500: '#183B65',
    600: '#0E233C',
    700: '#040B13',
    800: '#000000',
    900: '#000000',
  },
  credit: {
    // Cor primaria do Credito Justo
    DEFAULT: '#8D0FCE',
    50: '#E9C8FB',
    100: '#E0B0F9',
    200: '#CE80F6',
    300: '#BB51F2',
    400: '#A921EF',
    500: '#8D0FCE',
    600: '#6C0C9E',
    700: '#4C086F',
    800: '#2B053F',
    900: '#0B0110',
  },
};

const Popover: ComponentStyleConfig = {
  parts: ['popper'],
  baseStyle: () => ({
    popper: {
      zIndex: 1100,
    },
  }),
};

const FormControl: ComponentStyleConfig = {
  parts: ['FormControl'],
  defaultProps: {
    minH: '100px',
  },
};

const components: ThemeComponents = {
  Text,
  Input,
  Button,
  Popover,
  PinInput,
  FormLabel,
  Container,
  FormControl,
  Modal,
};

export default extendTheme(
  {
    colors: {
      brand: { ...COLORS.account },
      justa: { ...COLORS.brand },
    },
    components,
  },
  withDefaultColorScheme({
    colorScheme: 'brand',
    components: ['Input'],
  }),
);
