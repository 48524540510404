import { ComponentStyleConfig } from '@chakra-ui/theme';

export const Modal: ComponentStyleConfig = {
  variants: {
    'new-version': {
      dialog: {
        borderRadius: '16',
      },
      header: {
        mt: '8',
        mx: '34px',
        fontSize: '24px',
        fontFamily: 'Montserrat',
      },
      closeButton: {
        mt: '8',
        mx: '34px',
      },
      footer: {
        mb: '10',
        mr: '50px',
      },
      body: {
        ml: '34px',
        mr: '60px',
        fontFamily: 'Montserrat',
        color: ' #3D3D3D',
        fontSize: 'md',
      },
    },
  },
};
