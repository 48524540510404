const FormLabel = {
  variants: {
    onboarding: {
      mb: '14px',
      color: '#696F79',
      fontWeight: '600',
      lineHeight: '19.5px',
    },
  },
};

export default FormLabel;
