const Text = {
  variants: {
    'onb-step-title': {
      color: 'black',
      fontWeight: 700,
      fontSize: '32px',
      lineHeight: '39.01px',
    },
    'onb-step-subtitle': {
      mt: 6,
      mb: 8,
      fontWeight: 400,
      color: '#8692A6',
      fontSize: '24px',
      lineHeight: '28px',
      fontFamily: 'Inter',
    },
    'onb-welcome-title': {
      w: '100%',
      mt: '90px',
      maxW: '494px',
      color: 'black',
      fontSize: '48px',
      fontWeight: 700,
      lineHeight: '62.83px',
    },
    'onb-welcome-subtitle': {
      w: '100%',
      mt: '24px',
      maxW: '558px',
      fontSize: '32px',
      color: '#213A62',
      lineHeight: '39.01px',
    },
    'onb-meet-plans-title': {
      w: '100%',
      fontWeight: 700,
      fontSize: ' 48px',
      color: ' #000000',
      textAlign: 'center',
      fontFamily: 'Montserrat',
    },
    'onb-meet-plans-subtitle': {
      fontWeight: 400,
      color: '#8692A6',
      fontSize: '24px',
      lineHeight: '28px',
      textAlign: 'center',
      fontFamily: 'Inter',
    },
    'onb-confirmation-label': {
      mb: '12px',
      color: 'black',
      fontWeight: '700',
      lineHeight: '19.5px',
    },
    'onb-confirmation-data': {
      padding: 0,
      color: '#848484',
      fontSize: '24px',
      fontWeight: '600',
      lineHeight: '29.26px',
      fontFamily: 'Montserrat',
      _readOnly: {
        cursor: 'default',
      },
    },
  },
};

export default Text;
