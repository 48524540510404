export const checkPrivateRoute = (path: string) => {
  const publicRoutes = [
    '/login',
    '/recuperar-senha',
    '/onboarding',
    '/onboarding/finalizar',
    /^\/onboarding\/finalizar\?id=.*/,
    /^\/criar-senha\/.*/,
    /^\/forge-auth\/.*/,
  ];
  const isPrivate = !publicRoutes.some(route => {
    if (route instanceof RegExp) {
      return route.test(path);
    } else {
      return path === route;
    }
  });

  return isPrivate;
};
