import axios, { AxiosRequestHeaders } from 'axios';
import { parseCookies } from 'nookies';
import { isClient } from 'utils/isClient';
const BASE_URL = isClient
  ? process.env.NEXT_PUBLIC_GATEWAY_URL
  : process.env.GATEWAY_URL;

/**
 * Instancia com o Axios apontando para a rota base.
 * BASE_URL: http://gateway.justa.com.vc.
 */
const instance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
});

// Setup para o interceptor.
instance.interceptors.request.use(async config => {
  const cookies = parseCookies();
  const token = cookies.authToken;
  if (token) {
    (config.headers as AxiosRequestHeaders)['Authorization'] = `${token}`;
  }
  return config;
});

export default ['development', 'production'].includes(process.env.NODE_ENV)
  ? instance
  : axios;
