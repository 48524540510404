const Container = {
  variants: {
    onboarding: {
      maxW: '810px',
      p: { base: '32px', lg: '56px' },
    },
    'onb-main': {
      maxW: '780px',
      px: { base: '32px', lg: '70px', xl: '110px' },
      py: { base: '32px', lg: '30px' },
    },
    'onb-steps': {
      maxW: '546px',
      px: { base: 'unset', lg: '53px' },
    },
    'onb-welcome': {
      maxW: '560px',
      px: 'none',
    },
    'onb-qrcode': {
      maxW: { base: '624px', md: '780px' },
      px: { base: '32px', lg: '70px', xl: '110px' },
    },
  },
};

export default Container;
