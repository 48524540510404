import React from 'react';
import { useRouter } from 'next/router';
import { useEffect, ReactNode } from 'react';
import { getRefreshToken } from 'services/auth';
import Loading from '../components/Loading/index';
import { useAuth } from 'store/auth/index';

type PrivateRouteProps = {
  children: ReactNode;
};

export default function PrivateRoute({ children }: PrivateRouteProps) {
  const { push } = useRouter();
  const { isAuth, setAuth } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      if (!isAuth) {
        try {
          const resp = await getRefreshToken();
          if (resp.authorization) {
            setAuth(true);
          }
        } catch (error) {
          setAuth(false);
          push('/login');
        }
      }
    };

    fetchData();

    //eslint-disable-next-line
  }, []);

  return <>{isAuth ? children : <Loading />}</>;
}
