import Image from 'next/image';
import { Flex } from '@chakra-ui/react';

const Loading = () => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      height="90vh"
      width="100%"
    >
      <Image
        src="/img/logo-loading.gif"
        alt="Justa Logo"
        height={122}
        width={384}
        objectFit="contain"
        unoptimized
      />
    </Flex>
  );
};

export default Loading;
