import React from 'react';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Hydrate } from 'react-query/hydration';
import { ReactQueryDevtools } from 'react-query/devtools';
import * as Sentry from '@sentry/react';
import { DefaultSeo } from 'next-seo';
import { CacheProvider, EmotionCache } from '@emotion/react';
import GlobalStyles from 'styles/global';
import jstTheme from 'styles/theme';
import createEmotionCache from 'styles/createEmotionCache';
import nextSeoConfig from '../../next-seo.config';
import Script from 'next/script';
import { useRouter } from 'next/router';
import { checkPrivateRoute } from 'utils/checkPrivateRoute';
import PrivateRoute from 'utils/PrivateRoute';
import { MaintenancePage } from '../components/MaintenancePage';

const clientSideEmotionCache = createEmotionCache();

/** App props c/ cache do Emotion. */
type JstAppProps = AppProps & {
  emotionChache: EmotionCache;
};

const App = ({
  Component,
  pageProps,
  emotionChache = clientSideEmotionCache,
}: JstAppProps) => {
  const [queryClient] = React.useState(() => new QueryClient());
  const KONDUTO_KEY = process.env.NEXT_PUBLIC_KONDUTO_PUBLIC_KEY;
  const { asPath } = useRouter();
  const isPrivate = checkPrivateRoute(asPath);

  /**
   * ! A flag abaixo se habilitada irá exibir a página de manutenção.
   * Desabilitando assim o acesso a todas as páginas do site.
   */
  if (process.env.NEXT_PUBLIC_MAINTENANCE === 'true') {
    return <MaintenancePage />;
  }

  return (
    <>
      {/* Script do monitoramento do Hotjar */}
      <Script id="hotjar">
        {`(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:2986654,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
      </Script>
      {/* Script Konduto */}
      <Script id="konduto" type="text/javascript">
        {`
        var __kdt = __kdt || [];
        __kdt.push({ public_key: '${KONDUTO_KEY}' });
        (function () {
          var kdt = document.createElement('script');
          kdt.id = 'kdtjs';
          kdt.type = 'text/javascript';
          kdt.async = true;
          kdt.src = 'https://i.k-analytix.com/k.js';
          var s = document.getElementsByTagName('body')[0];
          s.parentNode.insertBefore(kdt, s);
        })();
        `}
      </Script>
      <CacheProvider value={emotionChache}>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={pageProps.dehydratedState}>
            <DefaultSeo {...nextSeoConfig} />
            <Head>
              <meta
                name="viewport"
                content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover"
              />
            </Head>
            <ChakraProvider theme={jstTheme}>
              <GlobalStyles />
              {isPrivate ? (
                <PrivateRoute>
                  <Component {...pageProps} />
                </PrivateRoute>
              ) : (
                <Component {...pageProps} />
              )}
              <ReactQueryDevtools />
            </ChakraProvider>
          </Hydrate>
        </QueryClientProvider>
      </CacheProvider>
    </>
  );
};

export default Sentry.withProfiler(App);
