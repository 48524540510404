import create from 'zustand';

export type Auth = {
  isAuth: boolean;
  setAuth: (value: boolean) => void;
  reset: () => void;
};

export const useAuth = create<Auth>(set => {
  const initialState = {
    isAuth: false,
  };

  return {
    ...initialState,
    setAuth: (isAuth: boolean) => set(state => ({ ...state, isAuth })),
    reset: () => set(() => initialState),
  };
});
