/** @type {import('next-seo').DefaultSeoProps} */
const defaultSEOConfig = {
  title: 'Conta Justa Web',
  titleTemplate: '%s | Conta Justa Web',
  defaultTitle: 'Conta Justa Web',
  description: 'Gerencie seus recebíveis de forma fácil, rápida e Justa!',
  canonical: 'https://conta.justa.com.vc',
  openGraph: {
    url: 'https://conta.justa.com.vc',
    title: 'Conta Justa Web',
    description: 'Gerencie seus recebíveis de forma fácil, rápida e Justa!',
    images: [
      {
        url: 'https://assets.justa.run/og-image.png',
        alt: 'Conta Justa Web og-image',
      },
    ],
    site_name: 'Conta Justa Web',
  },
  twitter: {
    handle: '@justacomvc',
    cardType: 'summary_large_image',
  },
};

export default defaultSEOConfig;
